function formatRate(override, from, to, suffix, lang = 'sk') {
  const langFrom = lang == 'sk' ? 'od' : 'from'
  const langTo = lang == 'sk' ? 'do' : 'to'

  let rate = ''
  if (override) {
    rate = override
  } else if (from && to) {
    rate = `${ from } – ${ to } ${ suffix }`
  } else if (from) {
    rate = `${ langFrom } ${ from } ${ suffix }`
  } else if (to) {
    rate = `${ langTo } ${ to } ${ suffix }`
  }

  return rate
}

export default formatRate
