var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-link',{attrs:{"to":{ name: 'dictionary-detail', params: { id: _vm.technology } }}},[_c('span',{staticClass:"tw-uppercase tw-rounded-sm tw-font-mono tw-inline-block tw-leading-none tw-transition tw-duration-150",class:{
      'tw-px-2 tw-py-1.5 tw-text-sm': _vm.size === 'base',
      'tw-px-3 tw-py-2 tw-text-base': _vm.size === 'lg',
      'tw-bg-gray-100 hover:tw-bg-gray-200 tw-text-gray-700': _vm.variant === 'light' && !_vm.userKnowTechnology,
      'tw-bg-gray-700 hover:tw-bg-gray-600 tw-text-white': _vm.variant === 'dark' && !_vm.userKnowTechnology,
      'tw-bg-green-alt hover:tw-bg-green-alt tw-text-green': _vm.variant === 'light' && _vm.userKnowTechnology,
      'tw-bg-green hover:tw-bg-green tw-text-green-alt': _vm.variant === 'dark' && _vm.userKnowTechnology
    }},[_vm._v(" "+_vm._s(_vm.technology)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }