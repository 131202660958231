<template>
  <k-layout>
    <k-container class="tw-bg-white tw-py-12 tw-px-4">
      <h1 class="tw-heading-1">{{ $t('global.titles.saved') }}</h1>
    </k-container>

    <k-container class="tw-py-12">
      <ka-user-saved-tab-projects />
    </k-container>
  </k-layout>
</template>

<script>
// import KaUserSavedTabPosts from "./ka-user-saved-tab-posts";
// import KaUserSavedTabEvents from "./ka-user-saved-tab-events";
// import KaUserVerificationTab from "./ka-user-verification-tab";
import KaUserSavedTabProjects from './ka-user-saved-tab-projects'

export default {
  components: {
    // KaUserSavedTabPosts,
    // KaUserSavedTabEvents,
    // KaUserVerificationTab,
    KaUserSavedTabProjects
  },
  created() {
    this.$store.dispatch('APPBAR_NON_TRANSPARENTABLE')
  },
  data() {
    return {
      tab: null
    }
  },
  computed: {
    user() {
      return this.$store.state.AUTH.user
    },
    formState: function () {
      return this.$store.state.USER.formState
    },
    formStateMessage: function () {
      return this.$store.state.USER.formStateMessage
    }
  },
  watch: {
    formState: {
      deep: true,
      handler(state) {
        Object.keys(state).forEach(name => {
          if (state[name] === 'SUCCESS') {
            setTimeout(() => {
              this.$store.commit('USER/SET_FORM_STATE', { name, state: 'IDLE' })
            }, 2500)
          }
        })
      }
    }
  },
  methods: {
    isState(name, state) {
      return this.formState[name] === state
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

::v-deep .v-tabs-bar {
  margin: 0 0.75rem;
  z-index: 1;
}

::v-deep .v-tabs-slider-wrapper {
  height: 0.25rem !important;
}
</style>
