<template>
  <div class="tw-group tw-relative tw-bg-white tw-rounded-2xl tw-overflow-hidden tw-h-full hover:tw-shadow-lg tw-transition tw-duration-200">
    <span v-if="top && !simple" class="tw-absolute tw-bg-koderia tw-px-2 tw-pb-0.5 tw-top-0 tw-right-0 tw-mr-8">
      <span class=" tw-text-white tw-text-sm tw-leading-none tw-uppercase">
        {{ $t('project.detail.top_offer') }}
      </span>
    </span>

    <router-link :to="{ name: 'project-detail', params: { id: project.id, locale } }" class="tw-no-underline">
      <div class="tw-px-8 tw-py-8 tw-flex tw-flex-col tw-justify-between tw-h-full">
        <div v-if="!simple && (project.isNew || project.remoteOfficePercentage || project.language)" class="tw-mb-2 ">
          <div class="tw-list-none tw-text-xs tw-m-0 tw-p-0 tw-flex tw-flex-row tw-space-x-1 tw-leading-none">
            <span
              v-if="project.isNew"
              class="tw-px-2 tw-py-1 tw-uppercase tw-font-mono tw-leading-none tw-bg-green-alt tw-text-green"
            >
              {{ $t('project.detail.status.new') }}
            </span>

            <span
              v-if="project.remoteOfficePercentage"
              class="tw-px-2 tw-py-1 tw-uppercase tw-font-mono tw-leading-none tw-bg-yellow-alt tw-text-yellow"
            >
              {{ $t('project.detail.status.remote') }} {{ project.remoteOfficePercentage }}%
            </span>

            <span
              v-if="project.language"
              class="tw-px-2 tw-py-1 tw-uppercase tw-font-mono tw-leading-none tw-bg-red-alt tw-text-red"
            >
              {{ project.language }}
            </span>
          </div>
        </div>

        <h3 class="tw-w-4/5 tw-heading-3" :class="{ 'simplified-title': simple }">{{ project.name }}</h3>

        <p
          v-if="project.description && !simple"
          class="tw-line-clamp-2 tw-mt-4 tw-text-sm tw-leading-6"
          v-html="shortDescription(project.description)"
        ></p>

        <div
          v-if="project.expertises && project.expertises.length > 0"
          class="tw-mt-4"
          :class="{ 'simplified-technologies-wrapper': simple }"
        >
          <div class="tw--mt-0.5 tw--ml-0.5">
            <k-technology-chip
              v-for="technology in project.expertises"
              :key="technology"
              :technology="technology"
              class="tw-m-1"
            />
          </div>
        </div>

        <div class="tw-mt-6">
          <dl class="tw-flex tw-flex-col tw-items-center tw-text-base tw-leading-none tw-space-y-4 sm:tw-flex-row sm:tw-space-y-0 sm:tw-space-x-10">
            <div class="tw-grid tw-grid-cols-3 tw-gap-1">
              <template v-if="isContract">
                <dt>{{ $t('project.detail.contract') }}</dt>
                <div class="tw-col-span-2">
                  <dd class="tw-flex tw-items-center tw-space-x-1">
                    <span class="">{{ formatRateContract }}</span>
                  </dd>
                </div>
              </template>

              <template v-else-if="isTPP">
                <dt>{{ $t('project.detail.tpp') }}</dt>
                <div class="tw-col-span-2">
                  <dd class="tw-flex tw-items-center tw-space-x-2">
                    <span class="">{{ formatRateTPP }}</span>
                  </dd>
                </div>
              </template>

              <template v-else>
                <template v-if="formatRateContract">
                  <dt>{{ $t('project.detail.contract') }}</dt>
                  <div class="tw-col-span-2">
                    <dd class="tw-flex tw-items-center tw-space-x-1">
                      <span class="">{{ formatRateContract }}</span>
                    </dd>
                  </div>
                </template>

                <template v-if=formatRateTPP>
                  <dt>{{ $t('project.detail.tpp') }}</dt>
                  <div class="tw-col-span-2">
                    <dd class="tw-flex tw-items-center tw-space-x-2">
                      <span class="">{{ formatRateTPP }}</span>
                    </dd>
                  </div>
                </template>
              </template>
            </div>


            <div v-if="!simple">
              <dt class="tw-sr-only">{{ $t('global.place') }}</dt>
              <dd class="tw-flex tw-items-center tw-space-x-2">
                <k-icon name="location" size="20" class="" />
                <span class="">{{ project.city || '–' }}</span>
              </dd>
            </div>
            <div v-if="!simple">
              <dt class="tw-sr-only">{{ $t('global.experience') }}</dt>
              <dd class="tw-flex tw-items-center tw-space-x-2">
                <k-icon name="skill" size="20" class="" />
                <span class="">{{ requiredLevels }}</span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { getRouteLocale } from '@web/constants/language'
import projectMixin from '@web/projects/projectMixin'

export default {
  props: {
    project: {
      type: Object
    },
    simple: {
      type: Boolean,
      default: false
    }
  },
  mixins: [projectMixin],
  computed: {
    locale() {
      return getRouteLocale()
    }
  },
  methods: {
    shortDescription(descriptionHTML) {
      if (!descriptionHTML) return ''

      // eslint-disable-next-line
      const text = descriptionHTML.replace(/<[^>]*>?/gm, '').replace(/(\r\n|\r|\n)\s+$/, ' ')

      if (text.length > 150) {
        return text.slice(0, 200).trim() + '...'
      }
      return text
    }
  }
}
</script>

<style lang="scss" scoped>
.simplified-title {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.simplified-technologies-wrapper {
  overflow: hidden;
  height: 70px;
}
</style>
