import formatRate from '@web/utils/format-rate'

export default {
  computed: {
    top() {
      return !!this.project?.tags.find(tag => tag === 'Top')
    },
    isContract() {
      return this.project?.legalRelationType?.value === 'CONTRACTOR'
    },
    isTPP() {
      return this.project?.legalRelationType?.value === 'EMPLOYEE'
    },
    formatRateContract() {
      return this.project
        ? formatRate(undefined, this.project.mdBudgetFrom, this.project.mdBudgetTo, `€ / ${ this.$i18n.t('global.time.manday') }`)
        : null
    },
    formatRateTPP() {
      return this.project
        ? formatRate(undefined, this.project.monthlySalaryFrom, this.project.monthlySalaryTo, `€ / ${ this.$i18n.tc('global.time.months', 0) }`)
        : null
    },
    requiredLevels() {
      return this.project?.requiredLevels?.map(level => level.displayString).join(', ') || '-'
    }
  }
}
